<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <Logo />

      <!-- Left Text-->
      <b-col
        :style="randomSlide"
        lg="8"
        class="slider-image d-none d-lg-flex align-items-center p-5"
      />
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Καλωσήρθατε στην {{ $t(appName) }}! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Παρακαλώ συνδεθείτε για να ξεκινήσετε
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Κωδικός</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>Ξεχάσατε τον κωδικό σας;</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Να με θυμάσαι
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                :disabled="invalid"
                type="submit"
                variant="primary"
                block
              >
                Σύνδεση
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Νέος στην πλατφόρμα μας; </span>
            <b-link :to="{name:'register'}">
              <span>&nbsp;Δημιουργήστε λογαριασμό</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              ή
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { formUtils } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import Logo from '@/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Logo,
  },
  mixins: [formUtils],
  data() {
    return {
      status: '',
      password: this.$isDevelopment ? 'K+150FqW2=K' : '',
      userEmail: this.$isDevelopment ? 'info@inspire-web.gr' : '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    appName() {
      const { appName } = $themeConfig.app
      return appName
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    randomSlide() {
      const imageNames = [1, 2, 3, 4]

      const number = imageNames[Math.floor(Math.random() * imageNames.length)]

      // eslint-disable-next-line import/no-dynamic-require
      const slide = require(`@/assets/images/auth/${number}.jpeg`)

      return {
        backgroundImage: `url(${slide})`,
      }
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('app-common/jwtLogin', {
            username: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const userData = response.data
              useJwt.setToken(userData.jwtToken)
              useJwt.setRefreshToken(userData.jwtToken)
              delete userData.jwtToken
              delete userData.password
              localStorage.setItem('userData', JSON.stringify(userData))

              window.location = getHomeRouteForLoggedInUser()
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: err || 'Λάθος e-mail ή κωδικός χρήστη',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
  .slider-image {
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
  }
</style>
